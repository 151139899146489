import FooterWrapper from '@/homepage/footer/footerWrapper';
import { Homepage } from '@/typings/types';
import LocationWrapper from '@/homepage/locations/locationWrapper';
import OllcaWrapper from '@/homepage/ollca/ollcaWrapper';

type PageFooterProps = {
  homepage: Homepage;
  disableOllca?: boolean;
};

const PageFooter = ({ homepage, disableOllca }: PageFooterProps) => {
  return (
    <>
      <div className={'mx-auto max-w-6xl 2xl:max-w-[85rem]'}>
        <LocationWrapper locations={homepage.locations} />
      </div>
      {!disableOllca && (
        <div className={'mx-auto max-w-6xl'}>
          <OllcaWrapper
            ollcaImage={homepage.ollcaImage}
            ollcaDescription={homepage.ollcaDescription}
            ollcaLinks={homepage.ollcaLinks}
          />
        </div>
      )}
      <div className={'mb-5 h-[102px] w-full bg-top-border'}></div>
      <FooterWrapper footer={homepage.footer} />
    </>
  );
};

export default PageFooter;
